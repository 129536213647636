@import "backgrounds";
@import "variables";

.pastry {
  display: flex;
  flex-direction: column;

  .row.right {
    flex-direction: row-reverse;
  }

  .pastryCategorie {
    min-height: 250px;
    scroll-margin-top: 80px;
  }

  .pastryCategorie.focus {
    width: 100%;
    background-color: $base-dark;
    color: $base-light;

    h3 {
      color: $base-light;
    }
  }

  .product {
    background-color: #DDDEDA;
    position: relative;

    &:hover {
      cursor: zoom-in;

      .productDescription {
        display: flex;

        .name {
          padding-right: 30px;
        }
      }

      .pricing-wrapper {
        background-color: $accent;

        .name {
          display: none;
        }
      }
    }

    .name {
      margin: 8px;
      min-height: 2.6em;
    }

    .productImage {
      overflow: hidden;
      padding: 0;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        object-position: 50% 50%;
      }

      .hasImages {
        position: absolute;
        top: 0;
        right: 0;
        padding: 6px;
        font-size: 24px;
        z-index: 999;
        pointer-events: none;
      }
    }

    .pricing-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-top: auto;

      div.price {
        flex-grow: 1;
        pointer-events: all;
        color: #fdfdfd;
        text-align: center;
        vertical-align: middle;
        font-size: 18px;
        line-height: 30px;
        padding: 4px 2px;
        margin: 2px;

        text-decoration: none;

        &:hover {
          background-color: $accent-dark;
          cursor: pointer;
          text-decoration: none;
        }

        i {
          pointer-events: none;
          font-size: 14px;
          position: relative;
          bottom: 1px;
        }

        .large {
          font-size: 30px;
          bottom: -2px;
        }
      }
    }

    .productDescription {
      pointer-events: none;
      padding: 8px;
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid $accent;
      box-shadow: 0 0 10px $accent;
      position: absolute;
      width: auto;
      height: auto;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      display: none;
      color: $base-dark;
      flex-flow: column;
    }
  }
}

@import url('https://fonts.googleapis.com/css?family=Abril+Fatface');
@import url('https://fonts.googleapis.com/css?family=Montserrat:500,600,700');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700');
@import url('https://fonts.googleapis.com/css2?family=Merriweather');
@font-face {
  font-family: 'Cinzel Decorative';
  src: url('../assets/fonts/cinzel-decorative/CinzelDecorative-Black.woff2') format('woff2'),
  url('../assets/fonts/cinzel-decorative/CinzelDecorative-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@import "variables";
@import "fonts";

/* ========== Footer Section ========== */
footer{
  float: left;
  width:100%;
  font-size: $size-default;
  background: $accent-dark;
  box-shadow: 0 0 10px rgba(0,0,0,0.23);
  .container {
	padding-bottom: $size-default;
	h3{
	  font-weight: bold;
	  margin-bottom: $size-medium;
	}
	a{
	  color: $base-light;
	  &:hover {
		text-underline: none;
	  }
	}
	.socials{
	  display:flex;
		i {
		  &:hover {
		  color: $base-dark;
		}
	  }
	  div {
		padding: $size-default;
	  }
	}
  }

}




@import 'variables';

.overlay-layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: overlay-fadeIn 0.5s 0.2s forwards;
  -moz-animation: overlay-fadeIn 0.5s 0.2s forwards;
  -o-animation: overlay-fadeIn 0.5s 0.2s forwards;
  animation: overlay-fadeIn 0.5s 0.2s forwards;
  z-index: 10001 !important;

  &.bg-transparent {
	&:before {
	  display: none;
	}
  }

  &:before {
	content: ' ';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	opacity: 0.9;
	background: white;
	border-radius: 10px;
  }

  .overlay-body {
	position: absolute;
	margin: 60px auto;
	max-width: 600px;
	background: white;
	border-radius: 10px;
	box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
	text-align: center;
	.images {
	  position: relative;
	  &.short{
		img {
		  border-radius: 10px;
		}
		.label{
		  border-radius: 0 0 10px 10px;
		}
	  }
	  &.full {
		img {
		  border-radius: 10px 10px 0 0;
		}

	  }
	  .label {
		position: absolute;
		padding: 3px;
		font-size:1.2rem;
		bottom: 0;
		width: 100%;
		background: white;
		opacity: 0.7;
	  }
	}

  }
}

.controls {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  padding: 0;
  border-radius: 0 0 10px 10px;
  > div {
	&:hover {
	  background-color: $accent;
	  color: $accent-white;
	  cursor: pointer;
	  border-radius: 0 0 10px 10px;
	}

	flex-grow: 1;
	text-align: center;
	padding: 4px;
  }
}


@-webkit-keyframes overlay-fadeIn {
  from {
	opacity: 0;
  }
  to {
	opacity: 1;
  }
}

@-moz-keyframes overlay-fadeIn {
  from {
	opacity: 0;
  }
  to {
	opacity: 1;
  }
}

@-o-keyframes overlay-fadeIn {
  from {
	opacity: 0;
  }
  to {
	opacity: 1;
  }
}

@keyframes overlay-fadeIn {
  from {
	opacity: 0;
  }
  to {
	opacity: 1;
  }
}

@import "backgrounds";
@import "variables";

.intro {
  min-height: 70%;
  box-shadow: 0 0 10px rgba(0,0,0,0.23);
  strong {
	font-family: 'Cinzel Decorative', serif;
	font-size: 1.2rem;
  }
}
.intro.intro-small{
  height: 50%;
}
.intro-bg{
  //@include blueberry-bg;
  background-color: $accent-dark;
  color: $base-light;
}
.caption-container{
  width:50rem;
  margin:0 auto;
}
.caption-two-panel{
  display: flex;
  align-items:center;
}

.intro h1{
  font-weight: 700;
  font-family: 'Cinzel Decorative', serif;
  font-size: 3rem;
  text-align: right;
  color: $base-light;
}
.intro h2{
  font-family: 'Cinzel Decorative', serif;
  font-size: 1.2rem;
  line-height: 2rem;
  color: $ak-grey;
}
.intro-caption .btn i{
  float: left;
  margin-right:15px;
}
.intro .carousel-item{
  height: 100vh;
}
.intro .carousel-item::before{
  content:"";
  background:rgba(0,0,0,0.6);
  position: absolute;
  width:100%;
  height: 100vh;
}
.intro {
  .intro-caption{
	p, div{
	  font-size: 1.2rem;
	  text-align: right;
	}
	a {
      color: $base-light;
	  font-weight: bold;
	}
  }

  .carousel-caption{
	top:25%;
  }
}

.intro .cta {
  flex-direction: row;
  text-align: right;
  p {
    display:flex;
    justify-content: space-between;
  }
  a {
	margin-bottom: 4px;
  }
}
.intro .socials{
  display: flex;
  flex-direction: row-reverse;
  text-align:right;
  a {
	padding-left: 30px;
  	color: $accent;
	&:hover{
	  color: $base-light;
	}
}

}
@media (max-width: 768px) {
  .intro p.cta{
	display: flex;
	flex-direction: column;
	a {
	  margin: 5px;
	}
  }
}

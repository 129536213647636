@import "variables";
@import "fonts";
@import '~bootstrap/scss/bootstrap.scss';

/* ========== Universal Stylings ========== */
body, html{
  height: 100%;
  width:100%;
}
body{
  font-size: $size-default;
  font-family: 'Source Sans Pro', serif;
  background-color: $base-light;
  color: $base-dark;
  height: 100%;
  overflow: auto;
}

#root{
  display: contents;
  min-height: 100%;
}
h1,h2,h3,h4,h5, .header{
  color: $base-dark;
  position: relative;
  font-family: 'Unica One', cursive;
}
p{
  h3, h4, h5, h6, .header {
	display: inline;
  }
}
section {
	float: left;
	width:100%;
	display: flex;
	align-items:center;
	position: relative;
	&.content{
	  min-height: 70%;
	}
}
.flex-row, .flex-column {
  display:flex;
}
.parallax{
  background-attachment: fixed!important;
}
.container {
  padding-top: 1rem;
}
.bg-primary{
  background-color: $base-light!important;
}
.bg-accent{
  background-color: $accent
}

.bg-overlay:before{
  content:"";
  background:rgba(250,250,250,0.6);
  position: absolute;
  height: 100%;
  width:100%;
  left:0;
  top:0;
}
.bg-overlay-primary:before{
  content:"";
  background:rgba(49,31,10,0.5);
  position: absolute;
  height: 100%;
  width:100%;
  left:0;
  top:0;
}
.btn{
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  margin: 0 8px;
  a {
	color: $base-dark;
  }
}
.btn-primary, .btn-cta{
  background-color: $accent-darker;
  border-color: $accent-darker;
}
.btn-cta {
  color: $base-light;
}
.btn-primary:hover,.btn-primary:focus, .btn-cta:hover, .btn-cta:focus{
  background-color: $accent;
  border-color: $accent;
  color: $base-light;
}
.btn-fake {
  margin: 0 8px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
}
.bg-white {
  color: $base-dark;
  h1,h2,h3,h4 {
	color: $base-dark;
  }
}
.hidden {
  display: none;
}
q {
  display: block;
  quotes: "“" "”" "‘" "’";
  font-weight:bold;
  color: $accent;
  font-family: "Unica One", serif;
  font-size: $size-medium;
  margin-top: 0;
  margin-bottom: 1rem;
  text-shadow: 2px 3px $black;
}
q::before {
  content: open-quote;
}
q::after {
  content: close-quote;
}

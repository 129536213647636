
$base-light: #fdfdfd;
$base-dark: #2e2a2b;
$accent-dark: #333333;
$accent-white: #eee;
$accent: #B47F41;
$accent-darker: #916634;
$ak-grey: #bdbcbcff;

$size-default: 16px;
$size-medium: 24px;
$size-large: 52px;

@import "variables";
@import "fonts";

#practical{
  border-top: 2px solid $accent-dark;
  border-bottom: 2px solid $accent-dark;
  padding-bottom: 2em;
  ul {
	line-height: 30px;
  }
  a {
	color: $base-dark;
	font-weight: bold;
	text-decoration: none;
  }
}

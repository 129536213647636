@import "variables";
@import "fonts";

/* ========== Top Navigation ========== */
.top-nav {
  height: 80px;
  transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  justify-content: space-between;
  a {
	color: $base-dark;
    padding: 3px;
  }
  .navbar-brand, .navbar-brand-small{
	margin-top: 2rem;
  }
  .navbar-nav {
	display: none;
	margin-left: auto;
	padding-left: 220px;
	li .nav-link {
	  color: $base-light;
      height: 100%;
	}
  }
  &.light-header{
	min-height: 60px;
	background: $base-light;
	box-shadow: 0 0 10px rgba(0,0,0,0.23);
	transition: 0.5s ease-in;
	-webkit-transition: 0.5s ease-in;
	-moz-transition: 0.5s ease-in;
	z-index: 9999;

	.navbar-nav{
	  display: flex;
	}
  }
}
//.top-nav li a.nav-link:hover, .top-nav .nav-item.active a.nav-link{
//  background-color: $accent;
//  border-color: $accent;
//  border-radius: 50px;
//  transition: 0.5s ease-in;
//  -webkit-transition: 0.5s ease-in;
//  -moz-transition: 0.5s ease-in;
//}
.top-nav .navbar-brand, .top-nav .navbar-brand-small{
  margin-top: 2rem;
  font-size: 28px;
  transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
}
.top-nav .search-box li a.nav-link{
  border:1px solid $accent;
  padding: 6px 15px;
}

.top-nav.light-header .navbar-brand{
  opacity: 0;
}
.navbar-brand-small{
  position: absolute;
  left: 45px;
  opacity: 0;
}
.top-nav.light-header .navbar-brand-small{
  opacity: 1;
  margin-top:0;
  display:inline-block;
}

@media (max-width: 768px) {
  .top-nav {
	display: none;
  }
}
